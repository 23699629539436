import React, { Component } from 'react';

import log from 'loglevel';

import CrashScreen from './crash-screen';

interface State {
  hasError: boolean;
}

interface Props {
  children: React.ReactNode;
}

log.enableAll();

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    log.error({ error, errorInfo });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <CrashScreen />;
    }

    return <>{children}</>;
  }
}

export default ErrorBoundary;
