import React from 'react';

import AOS from 'aos';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import SwiperCore, {
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  A11y
} from 'swiper';

import App from './app';
import rootReducer from './modules';

import './i18n';
import 'aos/dist/aos.css';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

type RootProps = {};

AOS.init();

SwiperCore.use([Mousewheel, Navigation, Pagination, Scrollbar, A11y]);

const store = createStore(
  rootReducer,
  (window as any).__REDUX_STATE__,
  composeWithDevTools()
);

// const basename =
//   process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_VERSION;

const Root: React.FC<RootProps> = () => {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </HelmetProvider>
  );
};

export default Root;
