import { ActionType, createAction, createReducer } from 'typesafe-actions';

export type ErrorType = 'UNAUTHORIZED' | 'UNKNOWN' | 'NOT_FOUND' | null;

// action type
const SET_ERROR_TYPE = 'error/SET_ERROR_TYPE';
const RESET = 'error/RESET';

// create action method
export const setErrorType = createAction(SET_ERROR_TYPE)<ErrorType>();
export const reset = createAction(RESET)();

// aciont object type
const actions = {
  setErrorType,
  reset
};
type ErrorAction = ActionType<typeof actions>;

type IErrorState = {
  errorType: ErrorType;
};

const initialState: IErrorState = {
  errorType: null
};

const error = createReducer<IErrorState, ErrorAction>(initialState, {
  [SET_ERROR_TYPE]: (state, action) => ({
    ...state,
    errorType: action.payload
  }),
  [RESET]: (state, action) => ({
    ...state,
    errorType: null
  })
});

export default error;
