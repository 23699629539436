import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyles = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;700&family=Poppins:wght@300;500;700&display=swap");

  ${reset}

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  li {
    list-style: none;
  }
  
  a {
    color: black;
    font-weight: initial;
    text-decoration: none;
  }
  
  button {
    display: flex;
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
  }
  
  html,
  body {
    position: relative;
    width: 100%;
    min-height: 100vh;
  }
  
  body {
    position: relative;
    font-family: "Poppins", "Noto Sans KR", sans-serif;
  }

  #root {
    height: 100%;
  }

  .error_main {
    height: calc(100vh - 160px);

    @media screen and (max-width: 1024px) {
      height: calc(100vh - 120px);
    }
  }

  /* ==error
  **********************************/
  .error_section {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 150px 0;
    position: relative;
    display: flex;
    flex-direction: column;

    h6 {
      font-size: 27px;
      font-weight: 700;
      color: #000;
      margin-bottom: 28px;
      line-height: 1.6;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.6;
      margin-top: 28;
      max-width: 600px;
    }

    a {
      margin-top: 40px;
    }

    .error_code {
      font-size: 14px;
      font-weight: 300;
      margin-top: 20px;
      text-transform: uppercase;
    }

    @media screen and (max-width: 1024px) {
      padding: 80px 0;

      h6 {
        font-size: 18px;
        margin-bottom: 18px;
      }

      p {
        font-size: 14px;
      }

      .error_code {
        font-size: 12px;
      }
    }
  }

  .error_section_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 1440px;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    z-index: 1;

    @media screen and (min-width: 1024px) and (max-width: 1440px) {
      min-width: 100%;
      padding: 0 60px;
    }

    @media screen and (max-width: 1024px) {
      min-width: 100%;
      padding: 0 20px;
    }
  }

  .error_img_wrap {
    position: relative;
    width: 292px;
    height: 170px;
    margin-top: 10px;
    margin-bottom: 60px;

    @media screen and (max-width: 1024px) {
      width: 180px;
      height: 104px;
    }
  }

  .error_img {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 292px;
    height: 170px;
    border: 2px solid #000;
    background-color: #fff;

    &:nth-child(1) {
      top: -5px;
      left: -5px;
      z-index: 3;
    }

    &:nth-child(2) {
      z-index: 2;
    }

    &:nth-child(3) {
      top: 5px;
      left: 5px;
      z-index: 1;
    }

    > span {
      font-size: 60px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-5%);
      flex: 1;
    }
    .error_img-top {
      display: flex;
      align-items: center;
      width: 100%;
      height: 30px;
      border-bottom: 2px solid #000;
      span {
        display: block;
        width: 12px;
        height: 12px;
        border: 2px solid #000;
        border-radius: 12px;
        margin-right: 6px;
        &:first-child {
          margin-left: 6px;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      width: 180px;
      height: 104px;

      > span {
        font-size: 34px;
      }

      .error_img-top {
        height: 18px;

        span {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
  
  .error_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 222px;
    height: 58px;
    background-color: #000;
    border: 2px solid #000;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #fff;
      color: #000;
    }

    @media screen and (max-width: 600px) {
      width: 180px;
      height: 48px;
      font-size: 14px;
    }
  }
`;
