import React from 'react';

import { GlobalStyles } from '../../styles/global-styles';

type CoreProps = {};

const Core: React.FC<CoreProps> = () => {
  return (
    <>
      <GlobalStyles />
    </>
  );
};

export default Core;
