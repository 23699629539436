import React from 'react';

type ErrorScreenTemplateProps = {
  title: string;
  desc: string;
  code: string;
};

const ErrorScreenTemplate: React.FC<ErrorScreenTemplateProps> = ({
  title,
  desc,
  code
}) => {
  return (
    <main className="error_main">
      <section className="error_section">
        <div className="error_section_wrap">
          <div className="error_img_wrap">
            <div
              className="error_img"
              data-aos="zoom-in-left"
              data-aos-delay="300"
            >
              <div className="error_img-top">
                <span />
                <span />
                <span />
              </div>
              <span>Error</span>
            </div>
            <div
              className="error_img"
              data-aos="zoom-in-left"
              data-aos-delay="100"
            >
              <div className="error_img-top">
                <span />
                <span />
                <span />
              </div>
              <span>Error</span>
            </div>
            <div
              className="error_img"
              data-aos="zoom-in-left"
              data-aos-delay="0"
            >
              <div className="error_img-top">
                <span />
                <span />
                <span />
              </div>
              <span>Error</span>
            </div>
          </div>
          <h6>{title}</h6>
          <p>{desc}</p>
          <a className="error_btn" href="/">
            Go to home
          </a>
          <span className="error_code">Error code : {code}</span>
        </div>
      </section>
    </main>
  );
};

export default ErrorScreenTemplate;
