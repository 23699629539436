import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './locales/en/translation.json';
import ko from './locales/ko/translation.json';

// import Backend from 'i18next-http-backend';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
// if (navigator.language) {
//   const defaultLanguage = navigator.language.split(/[-_]/)[0];
//   if (defaultLanguage && defaultLanguage === 'ko') {
//     setLanguage('ko');
//   }
// }

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // backend: {
    //   loadPath: 'https://resource.zenaad.com/locales/{{lng}}/{{ns}}.json',
    //   crossDomain: false
    // },

    resources: {
      en: {
        translation: en
      },
      ko: {
        translation: ko
      }
    },

    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },

    react: {
      wait: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: [
        'br',
        'strong',
        'i',
        'sub',
        'sup',
        'li',
        'span'
      ]
    }
  });

export default i18n;
