import React, { Suspense } from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';

import ErrorBoundary from './components/error/error-boundary';
import NotFoundScreen from './components/error/not-found-screen';
import Core from './containers/base/core';

const HomePage = React.lazy(() => import('./pages/home-page'));
const PlankTimerPage = React.lazy(() => import('./pages/plank-timer-page'));
const MatchRunePage = React.lazy(() => import('./pages/match-rune-page'));
const CubePlusPage = React.lazy(() => import('./pages/cube-plus-page'));
const MergeRunePage = React.lazy(() => import('./pages/merge-rune-page'));
const AquaPangPage = React.lazy(() => import('./pages/aqua-pang-page'));
const UnionStarPage = React.lazy(() => import('./pages/union-star-page'));
const PackagingMasterPage = React.lazy(
  () => import('./pages/packaging-master-page')
);
const AdSkipPage = React.lazy(() => import('./pages/ad-skip-page'));
const OneshotSniperPage = React.lazy(
  () => import('./pages/oneshot-sniper-page')
);
const NightArcherPage = React.lazy(() => import('./pages/night-archer-page'));
const AboutPage = React.lazy(() => import('./pages/about-page'));

function App() {
  return (
    <>
      <Helmet>
        <title>ZENAAD</title>
      </Helmet>

      <ErrorBoundary>
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/plank-timer" component={PlankTimerPage} />
            <Route path="/match-rune" component={MatchRunePage} />
            <Route path="/cube-plus" component={CubePlusPage} />
            <Route path="/merge-rune" component={MergeRunePage} />
            <Route path="/aqua-pang" component={AquaPangPage} />
            <Route path="/union-star" component={UnionStarPage} />
            <Route path="/packaging-master" component={PackagingMasterPage} />
            <Route path="/ad-skip" component={AdSkipPage} />
            <Route path="/oneshot-sniper" component={OneshotSniperPage} />
            <Route path="/night-archer" component={NightArcherPage} />
            <Route path="/about" component={AboutPage} />
            <Route path="*" component={NotFoundScreen} />
          </Switch>
        </Suspense>
      </ErrorBoundary>

      <Core />
    </>
  );
}

export default App;
