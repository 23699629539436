import React from 'react';

import ErrorScreenTemplate from './error-screen-template';

type NotFoundScreenProps = {};

const NotFoundScreen: React.FC<NotFoundScreenProps> = () => {
  return (
    <ErrorScreenTemplate
      title="Sorry, this page isn't available."
      desc="The link you followed may be broken, or the page may have been removed."
      code="404"
    />
  );
};

export default NotFoundScreen;
