import React from 'react';

import ErrorScreenTemplate from './error-screen-template';

type ErrorScreenProps = {};

const ErrorScreen: React.FC<ErrorScreenProps> = () => {
  return (
    <ErrorScreenTemplate
      title="Sorry! It's not you. It's us"
      desc={`We're experiencing an internal server error.\n
Please try again later.`}
      code="500"
    />
  );
};

export default ErrorScreen;
